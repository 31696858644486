<template>
    <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="6">
                        <h3>Categorias</h3>
                    </vs-col>
                    <vs-col vs-offset="5" vs-lg="1" vs-align="flex-end">
                        <vs-button @click="nuevo" color="success" type="relief">Agregar Nuevo</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12">
                        
                            <div slot="header">
                                <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="card-title">Categorias</h5>
                                    <h6 class="card-subtitle mb-0">Todos los participantes, ya sean de seccion especial o de concurso</h6>
                                </div>
                                </div>
                            </div>
                            <VityTable 
                                v-if="url"
                                :columns="columns"
                                :url="url+'categories'"
                                :params="{admin: 1, all:1}"
                                :swipeResult="swip"
                            />
                    
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="categoria">
                <h4 class="card-title d-flex">
                    Categoria {{categoria.name}}
                </h4>
                <p class="card-subtitle">
                    Aqui podras cambiar toda la informacion de la categoria
                </p>
                
                <vs-row vs-justify="center">
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="50" label="Nombre" class="inputx" placeholder="Nombre" v-model="categoria.name"/>
                    </vs-col>
                    <vs-col vs-lg="2" vs-xs="2">
                        <label for="" class="mr-2 mb-0">¿Profesional?</label>
                        <vs-switch color="success" v-model="categoria.professional"/>
                    </vs-col>
                    <vs-col vs-lg="2" vs-xs="2">
                        <label for="" class="mr-2 mb-0">¿Extra?</label>
                        <vs-switch color="success" v-model="categoria.extra"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Creado el" class="inputx" placeholder="Creado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', categoria.created_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Actualizado el" class="inputx" placeholder="Actualizado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', categoria.updated_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12" v-if="!categoria.professional && !categoria.extra">
                        <label>Activado el</label><br>
                        <v-date-picker v-model="categoria.actived_at" mode="dateTime" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                class="px-2 py-1 border focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12" v-if="!categoria.professional && !categoria.extra">
                        <label>Cerrado el</label><br>
                        <v-date-picker v-model="categoria.closed_at" mode="dateTime" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                class="px-2 py-1 border focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col vs-lg="12" vs-xs="12">
                        <vs-button @click="save" color="success" type="relief">Guardar</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>
        </vs-col>
    </vs-row>
</template>

<script>
import axios from 'axios'
import VityTable from "@/components/VityTable.vue";

export default {
    name: 'Participantes',
    components: {
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Nombre',
                    dbName: 'name',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Profesional',
                    dbName: 'professional',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Extra',
                    dbName: 'extra',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/categorias/TPLData">EDITAR</a>',
                    reference: 'id'
                },
            ],
            swip: {
                column: [2, 3],
                data: {
                    '0': 'NO',
                    '1': 'SI'
                }
            },
            url: process.env.VUE_APP_URL,
            categoria: null,
            descripcionDanger: false,
            required: [
                {
                    name: 'name',
                    viewName: 'Nombre'
                }
            ],
            nuevaImagen: null
        } 
    },
    methods:{
        get: function(id){
            if(!id)
                this.participant = null;
            else{
                axios
                    .get(process.env.VUE_APP_URL+'categories/'+id, {
                        params:{
                            admin: 1
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.categoria = rs.data;
                            this.categoria.professional = this.categoria.professional ? true : false;
                            this.categoria.extra = this.categoria.extra ? true : false;
                        }
                        else{
                            this.categoria = null;
                            this.$parent.$parent.errors = ['Participante no encontrado'];
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$parent.$parent.errors = ['Fallo al conectar'];
                    });
            }
        },
        save: function(){
            if(this.$parent.$parent.checkParams(this.required, this.categoria))
                return;

            var params = new FormData();
            params.append('professional', this.categoria.professional ? 1 : 0);
            params.append('extra', this.categoria.extra ? 1 : 0);
            params.append('name', this.categoria.name);
            params.append('icon', 'i');
            if(this.categoria.actived_at)
                params.append('actived_at', this.$parent.$parent.formatDate('Y-m-d H:i:s', this.categoria.actived_at));
            if(this.categoria.closed_at)
                params.append('closed_at', this.$parent.$parent.formatDate('Y-m-d H:i:s', this.categoria.closed_at));
            
            if(this.categoria.id){
                axios
                    .post(process.env.VUE_APP_URL+'categories/'+this.categoria.id, params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.get(this.$route.params.id);
                            //this.$router.push('participantes/'+rs.data);
                            this.$parent.$parent.aciertos = ['Guardado correctamente'];
                        }
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    });
            }
            else{
                axios
                    .post(process.env.VUE_APP_URL+'categories', params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK')
                            this.$router.push('/categorias/'+rs.data);
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    });
            }
        },
        nuevo: function(){
            this.$router.push('/categorias/new');
        },
        cambiarFoto: function(data){
            this.nuevaImagen = data.target.files[0];
        }
    },
    created: function(){
        if(this.$route.params.id == 'new')
            this.categoria = {};
        else
		    this.get(this.$route.params.id);
	},
	beforeRouteUpdate (to, from, next) {
        if(to.params.id == 'new')
            this.categoria = {};
        else
		    this.get(to.params.id);
		next();
	}
}
</script>